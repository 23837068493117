import { styled } from "@mui/system";
import SelectProperty from "./controls/SelectProperty";
import React, { useEffect, useState } from "react";
import { getBackgroundInput } from "./utils/getBackgroundInput";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import RpcSubscribeWrapper from "../../side-card/static-table/RpcSubscribeWrapper";
import { gql, useMutation } from "@apollo/client";

import customParseFormat from "dayjs/plugin/customParseFormat";
import DateRange from "../base/DateRange";
import Badge from "@mui/material/Badge";
import GetAppIcon from "@mui/icons-material/GetApp";
import SelectAggregation from "./controls/SelectAggregation";
import { getPropertyByKey } from "../../../utils/getPropertyByKey";
import { downloadCSV } from "./utils/downloadCsv";
import SelectGeotag from "./controls/SelectGeotag";
import SelectGroup from "./controls/SelectGroup";

dayjs.extend(customParseFormat);

const CREATE_EXECUTION = gql`
    mutation createControlsExecution($input: CreateControlExecutionInput!) {
        createControlExecution(input: $input) {
            clientMutationId
            controlExecution {
                id
            }
        }
    }
`;

const UPDATE_PROPERTIES_BY_KEYS = gql`
    mutation updateObjectPropertiesByKeys($input: UpdateObjectPropertiesByKeyInput!) {
        updateObjectPropertiesByKey(input: $input) {
            clientMutationId
        }
    }
`;

const ControlsPropertyHistoryTable = (
  {
    fgColor,
    bgColor,
    settingsStyle,
    settingsProperty,
    settingsSchema,
    settingsDateRange,
    settingsPeriod,
    settingsGroup,
    settingsGeotag,
    settingsWidgetControls,
    settingsLinkedOnly,
    columns,
    item,
    value,
  },
) => {
  const [date, setDate] = useState([]);
  const [object, setObject] = useState(null);
  const [geotag, setGeotag] = useState(null);
  const [monitorGroup, setMonitorGroup] = useState(null);
  const [period, setPeriod] = useState(null);
  const [periodName, setPeriodName] = useState(null);
  const [isMayGetData, setIsMayGetData] = useState(false);
  const [createExecution, { loading }] = useMutation(CREATE_EXECUTION);
  const [updateProperties] = useMutation(UPDATE_PROPERTIES_BY_KEYS);

  const periods = getPropertyByKey(item.objectProperties, "settingsPeriod").spec.valueSet.list.map((item) => ({
    title: item.title,
    value: item.key,
  }));

  const computeColumns = () => {
    let template = "";

    if (settingsWidgetControls.propertySelect) {
      template += "1fr ";
    }

    if (settingsWidgetControls.geotagSelect) {
      template += "1fr ";
    }

     if (settingsWidgetControls.monitorGroup) {
      template += "1fr ";
    }

    if (settingsWidgetControls.aggregationFunctionSelect) {
      template += "130px ";
    }

    if (settingsWidgetControls.intervalRange) {
      template += "180px ";
    }

    if (settingsWidgetControls.csvButton) {
      template += "32px ";
    }

    template += "32px";

    return template;
  };

  const Wrapper = styled("form")(() => ({
    [`&`]: {
      padding: "8px 16px",
      width: "100%",
      display: "grid",
      gridGap: "8px",
      justifyContent: "flex-end",
      gridTemplateColumns: computeColumns(),
    },
  }));

  const CustomButton = styled(Button)(() => ({
    "&": {
      backgroundColor: getBackgroundInput(settingsStyle),
      borderColor: fgColor,
      color: fgColor,
      borderRadius: "5px",
      minWidth: "32px",
      height: '32px',
      padding: "0px",
    },

    "&:hover": {
      border: `1px solid ${fgColor}`,
    },

    "& .MuiButton-icon": {
      margin: "0",
    },
  }));

  const handleSubmit = () => {
    const range = [
      `${date[0].format("YYYY-MM-DD")} 00:00`,
      `${date[1].format("YYYY-MM-DD")} 23:59`,
    ];

    return updateProperties({
      variables: {
        input: {
          objectId: item.id,
          propertiesArray: [
            {
              propertyKey: "settingsDateRange",
              value: {
                title: `${date[0].format("DD-MM-YYYY")}—${date[1].format("DD-MM-YYYY")}`,
                value: range,
              },
            },
            {
              propertyKey: "settingsProperty",
              value: object,
            },
            {
              propertyKey: "settingsGeotags",
              value: geotag,
            },
            {
              propertyKey: "settingsMonitor",
              value: monitorGroup,
            },
            {
              propertyKey: "settingsPeriod",
              value: {
                title: periodName,
                value: period,
              },
            },
          ],
        },
      },
    }).then(() => {
      return rpcHandler({
        property: object.value,
        period: {
          title: periodName,
          value: period,
        },
        range,
      });
    });
  };

  const rpcHandler = ({ object, period, range }) => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: "UpdateReport",
            objectId: item.id,
            params: {
              object,
              period,
              range,
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    if (settingsProperty?.value) {
      setObject(settingsProperty);
    }
  }, [settingsProperty]);

  useEffect(() => {
    if (settingsGeotag?.value) {
      setGeotag(settingsGeotag);
    }
  }, [settingsGeotag]);

  useEffect(() => {
    if (settingsGroup?.value) {
      setMonitorGroup(settingsGroup);
    }
  }, [settingsGroup]);

  useEffect(() => {
    if (settingsPeriod.value) {
      setPeriod(settingsPeriod.value);
      setPeriodName(settingsPeriod.title);
    }
  }, [settingsPeriod]);

  useEffect(() => {
    if (settingsDateRange.value) {
      const startDate = settingsDateRange.value[0];
      const endDate = settingsDateRange.value[1];

      setDate([dayjs(startDate, "YYYY-MM-DD"), dayjs(endDate, "YYYY-MM-DD")]);
    } else {
      setDate([]);
    }
  }, [settingsDateRange]);

  useEffect(() => {
    if (!object || !date?.length || !settingsSchema) {
      setIsMayGetData(false);
    } else {
      setIsMayGetData(true);
    }
  }, [object, date, settingsSchema, columns]);

  useEffect(() => {
    if (settingsSchema && !settingsSchema?.value) {
      // setObject(null);
    }
  }, [settingsSchema]);


  const isSameObject = () => {
    if (!settingsProperty?.value || !object?.value) {
      return true;
    }

    return settingsProperty?.value === object?.value;
  };

  const isSamePeriod = () => {
    if (!settingsDateRange?.value || !date.length) {
      return true;
    }

    return settingsDateRange.value[0] === `${date[0].format("YYYY-MM-DD")} 00:00` && settingsDateRange.value[1] === `${date[1].format("YYYY-MM-DD")} 23:59`;
  };

  return (
    <Wrapper onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
    }}>
      { settingsWidgetControls.propertySelect && (
        <SelectProperty
          item={item}
          value={object}
          settingsStyle={settingsStyle}
          settingsLinkedOnly={settingsLinkedOnly}
          fgColor={fgColor}
          bgColor={bgColor}
          title="Property"
          label="Property"
          schemaId={settingsSchema}
          name="propertyId"
          handleChange={(e) => {
            setObject(e);
          }}
        />
      )}

      { settingsWidgetControls.geotagSelect && (
        <SelectGeotag
          item={item}
          value={geotag}
          settingsStyle={settingsStyle}
          settingsLinkedOnly={settingsLinkedOnly}
          fgColor={fgColor}
          bgColor={bgColor}
          title="Geotag"
          label="Geotag"
          name="geotag"
          handleChange={(e) => {
            setGeotag(e);
          }}
        />
      )}

      { settingsWidgetControls.monitorGroup && (
        <SelectGroup
          item={item}
          value={monitorGroup}
          settingsStyle={settingsStyle}
          settingsLinkedOnly={settingsLinkedOnly}
          fgColor={fgColor}
          bgColor={bgColor}
          title="Group"
          label="Group"
          name="group"
          handleChange={(e) => {
            setMonitorGroup(e);
          }}
        />
      )}

      {settingsWidgetControls.aggregationFunctionSelect && (
        <SelectAggregation
          settingsStyle={settingsStyle}
          fgColor={fgColor}
          bgColor={bgColor}
          title="Aggregation"
          label="Aggregation"
          name="aggregation"
          value={period}
          options={periods}
          handleChange={(e, data) => {
            setPeriod(e.target.value);
            setPeriodName(data.props.children);
          }}
        />)
      }
      {settingsWidgetControls.intervalRange && (
        <DateRange
          isShowPresets={settingsWidgetControls.datePresets}
          title="Date range"
          settingsStyle={settingsStyle}
          fgColor={fgColor}
          date={date}
          onChange={(e) => {
            setDate(e);
          }}
        />
      )}
      <Badge
        sx={{
          "& .MuiBadge-badge": {
            transform: "translate(10px, -3px)",
            height: "10px",
            width: "10px",
            borderRadius: "5px",
            backgroundColor: "#FF7518",
          },
        }}
        invisible={true}
        variant="dot"
      >
        <RpcSubscribeWrapper
          rpcName={"UpdateReport"}
          objectId={item.id}
          disabled={!isMayGetData}
          object={item}
          title={""}
          successCb={() => {
          }}
          handler={handleSubmit}
        >
          <CustomButton
            title="Update table"
            size="small"
            variant="outlined"
            startIcon={<RefreshIcon />}
          >
          </CustomButton>
        </RpcSubscribeWrapper>
      </Badge>

      {settingsWidgetControls.csvButton && (
        <CustomButton
          title="Download CSV"
          size="small"
          variant="outlined"
          onClick={() => {
            downloadCSV(item, settingsProperty, value);
          }}
          startIcon={<GetAppIcon />}
        >
        </CustomButton>
      )}
    </Wrapper>
  );
};

export default ControlsPropertyHistoryTable;
