import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { gql, useLazyQuery } from "@apollo/client";
import { Map } from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { useDebounce } from "react-use";
import { msg } from "../messages";
import { setSettings } from "../store/settingsSlice";
import { isDashboard, isGroup, isReport } from "../utils/objectType";
import useCustomNavigate from "./hooks/useCustomNavigate";
import BulletInBoard from "./icons/bulletInBoard";
import NotificationsModal from "./modals/NotificationsModal";
import CloseIcon from "@mui/icons-material/Close";

const SEARCH = gql`
  query loadPure($filter: ObjectFilter) {
    objects(first: 5, filter: $filter) {
      id
      name
      schemaTags
      containerType: dynamicAttribute(
        match: { groupName: "General", property: "Container type", index: "0" }
      )
      objectsToObjectsByObject2Id {
        object1 {
          id
          name
        }
      }
    }
  }
`;

const SEARCH_IN_DASHBOARD = gql`
  query loadPure($id: UUID!, $filter: ObjectFilter) {
    object(id: $id) {
      objectsToObjectsByObject1Id(first: 5, filter: { object2: $filter }) {
        id
        object2 {
          id
          name
          schemaTags
          containerType: dynamicAttribute(
            match: {
              groupName: "General"
              property: "Container type"
              index: "0"
            }
          )
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  searchBar: {
    display: "flex",
    flex: 1,
    padding: "0",
    alignItems: "center",
    marginTop: "8px",
    marginRight: "0",
  },
  atfOutlinedInput: {
    "&:not(hover):not($atfFocused) $atfNotchedOutline":
      {
        borderColor: "transparent", //default
      },
    "&:hover:not($atfFocused) $atfNotchedOutline": {
      borderColor: "transparent", //hovered
    },
    "&$atfFocused $atfNotchedOutline": {
      border: "transparent", //focused
    },
  },
  atfNotchedOutline: {},
  atfFocused: {},
  inputRoot: {
    fontSize: "16px",
    fontFamily: "Roboto-Regular",
  },
}));

// if back = false, show menu icon. if not, show back arrow and use back content as text
const MainToolbar = (props) => {
  // type - type of currently displayed object (dashboard, group, widget, ..)
  const { setNotificationsModalOpen } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useCustomNavigate();

  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isDrawerOpen = useSelector((state) => state.settings.isDrawerOpen);
  const { dashboardId, reportId } = useParams();

  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    dispatch(setSettings({ isDrawerOpen: !isDrawerOpen }));
  };

  const [searchField, setSearchField] = useState("");
  const [options, setOptions] = useState([]);
  const [loadObject, { loading }] = useLazyQuery(SEARCH_IN_DASHBOARD, {
    fetchPolicy: "no-cache",
  });

  const [loadObjectFromOther, { loading: loading2 }] = useLazyQuery(SEARCH, {
    fetchPolicy: "no-cache",
  });

  const getIconByTags = (option) => {
    if (!option.id) return null;

    if (option.containerType === "minimap") {
      return <Map sx={{ color: "text.secondary" }} />;
    }
    if (isGroup(option.schemaTags)) {
      return <BulletInBoard sx={{ color: "text.secondary" }} />;
    }
    if (isDashboard(option.schemaTags) || isReport(option.schemaTags)) {
      return <DashboardIcon sx={{ color: "text.secondary" }} />;
    }
  };

  const isCurrent = (id) => {
    return id === dashboardId || id === reportId;
  };

  const loadCb = async () => {
    const data = await Promise.all([
      loadObject({
        fetchPolicy: "network-only",
        variables: {
          id: dashboardId || reportId,
          filter: {
            schemaTags: {
              overlaps: ["group"],
            },
            name: { includesInsensitive: searchField },
          },
        },
      }),
      loadObjectFromOther({
        fetchPolicy: "network-only",
        variables: {
          filter: {
            objectsToObjectsByObject2IdConnection: {
              every: {
                object1Id: {
                  notEqualTo: dashboardId,
                },
              },
            },
            schemaTags: {
              overlaps: ["dashboard", "report"],
            },
            or: [
              {
                name: { includesInsensitive: searchField },
              },
              {
                stringId: { includesInsensitive: searchField },
              },
            ],
          },
        },
      }),
    ]);

    const groups = data[0]?.data?.object?.objectsToObjectsByObject1Id?.map(
      (item) => item.object2
    ) || [];

    const dashboards = data[1]?.data?.objects?.filter((item) => isDashboard(item.schemaTags));
    const reports = data[1]?.data?.objects?.filter((item) => isReport(item.schemaTags));

    setOptions([
      ...groups,
      ...dashboards,
      ...reports,
    ]);
  };

  const [, cancel] = useDebounce(loadCb, 500, [searchField]);
  const [open, setOpen] = useState(false);

  const handleSearchField = (event, value, reason) => {
    if (reason === "input") {
      setSearchField(value);
    }
  };

  return (
    <Paper component="div" className={classes.searchBar} elevation={4}>
      <Autocomplete
        ListboxProps={{ style: { maxHeight: "350px" } }}
        value={searchField}
        onInputChange={(event, value, reason) =>
          handleSearchField(event, value, reason)
        }
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionDisabled={(option) => isCurrent(option.id)}
        fullWidth
        disableClearable
        freeSolo
        groupBy={(option) => {
          if (!option?.schemaTags) {
            return
          }
          if (isReport(option.schemaTags)) {
            return "Reports"
          }

          if (isDashboard(option.schemaTags)) {
            return "Dashboards"
          }

          if (isGroup(option.schemaTags)) {
            return "Groups"
          }
        }}

        componentsProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 8],
                },
              },
            ],
          },
        }}
        filterOptions={(options) => options}
        filterSelectedOptions={false}
        noOptionsText="No data"
        loading={loading}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        classes={{ inputRoot: classes.inputRoot }}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
            InputProps={{
              ...params.InputProps,
              "data-test": "search",
              style: { paddingTop: "3px", paddingBottom: "3px" },
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={handleDrawerToggle}
                    data-test="menu"
                    size="small"
                  >
                    <MenuIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchField.length > 0 && (
                  <IconButton
                    aria-label="search"
                    onClick={() => {
                      setSearchField('')
                    }}
                    size="small"
                  >
                    <CloseIcon />
                  </IconButton>
                  )}

                  {!desktop && (
                    <>
                      <Divider
                        orientation="vertical"
                        style={{
                          backgroundColor: "#686868",
                          marginLeft: "10px",
                          marginRight: "10px",
                          height: "32px",
                        }}
                      />
                      <NotificationsModal />
                    </>
                  )}
                </InputAdornment>
              ),
              classes: {
                root: classes.atfOutlinedInput,
                focused: classes.atfFocused,
                notchedOutline: classes.atfNotchedOutline,
              },
            }}
            placeholder={msg.appBar.search}
          />
        )}
        renderOption={(props, option) => (
          <>
            {!option.isDivider && (
              <li
                {...props}
                data-test-search={option.name}
                key={props.id}
                onClick={() => {
                  dispatch(setSettings({ isEditMode: false }));
                  setSearchField("");
                  setOpen(false);

                  if (isDashboard(option.schemaTags)) {
                    history(`/boards/${option.id}`);
                  }

                  if (isReport(option.schemaTags)) {
                    history(`/reports/${option.id}`);
                  }

                  if (isGroup(option.schemaTags)) {
                    if (dashboardId) {
                      history(`/boards/${dashboardId}/${option.id}`);
                    } else {
                      history(`/reports/${reportId}/${option.id}`);
                    }
                  }
                }}
                style={{ height: "48px" }}
              >
                <Grid container alignItems="center">
                  <Grid item sx={{ display: "flex", width: 44 }}>
                    {getIconByTags(option)}
                  </Grid>
                  <Grid
                    item
                    sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                  >
                    <Box component="span">{option.name}</Box>
                  </Grid>
                </Grid>
              </li>
            )}

            {option.isDivider && <Divider />}
          </>
        )}
      />
    </Paper>
  );
};

export default MainToolbar;
