import { Button, Checkbox, Collapse, FormControl, FormControlLabel, FormGroup, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { ChangeEvent, useEffect } from "react";
import { create, InstanceProps } from "react-modal-promise";
import { dispatch as dispatchBus } from "use-bus";
import * as yup from "yup";
import CommonModal from "../../../components/CommonModal";
import { msg } from "../../../messages";
import {
  CHART_TYPE_OPTIONS,
  LINE_WIDTH_OPTIONS,
  SettingsFormatStaticTable,
  SETTINGS_SIZE_OPTIONS,
  SETTINGS_STATIC_TABLE_FORMAT,
  SETTINGS_STYLE_OPTIONS,
  SMOOTH_TYPE_OPTIONS,
  TypeOptionsEnum,
} from "../../../utils/constants/selectOptions";
import { getPropertyValueByKey } from "../../../utils/getPropertyByKey";
import { WIDGETS_ENUM } from "../../../utils/widgetTypes";
import CustomInput from "../../CustomInput";
import CustomSelect from "../../CustomSelect";
import CustomSwitch from "../../CustomSwitch";
import { ScaleOptionsEnum, SCALE_OPTIONS } from "../regular-chart/constants";
import { TDefaultWidgetProps } from "../types";
import { useAddWidget } from "../useAddWidget";
import { FORM_DEFAULT_VALUES } from "./constants";

const CreatePropertyHistoryTable = (props: TDefaultWidgetProps & InstanceProps<{}>) => {
  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const { createWidgetFn, updateWidget, isLoading } = useAddWidget({
    group: props.group,
    widgetType: WIDGETS_ENUM.PROPERTY_HISTORY_TABLE,
    cb: submit,
  });

  const isEdit = () => {
    return props?.widget?.id !== undefined;
  };

  const getPrevValues = () => {
    const defaultValues = {
      ...FORM_DEFAULT_VALUES,
    };

    if (props?.widget?.objectProperties) {
      for (const property in FORM_DEFAULT_VALUES) {
        defaultValues[property] = getPropertyValueByKey(props.widget.objectProperties, property);
      }
    }

    return defaultValues;
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .test("name", "Name is required", (value) => !isEdit() || (value && value.trim() !== "")),
    properties: yup.object({
      settingsYaxisScale: yup.string().required("Type is required"),
      settingsMinimum: yup.number().when("settingsYaxisScale", {
        is: (type: ScaleOptionsEnum) => type === ScaleOptionsEnum.fixed,
        then: yup.number().required("Min is required"),
        otherwise: yup.number().notRequired(),
      }),
      settingsMaximum: yup.number().when("settingsYaxisScale", {
        is: (type: ScaleOptionsEnum) => type === ScaleOptionsEnum.fixed,
        then: yup.number().required("Max is required").moreThan(yup.ref("settingsMinimum"), "Must be greater than min"),
        otherwise: yup.number().notRequired(),
      }),
    }),
  });

  const formik = useFormik({
    initialValues: {
      description: isEdit() ? props.widget.description : "",
      name: props.name,
      properties: {
        ...getPrevValues(),
      },
    },
    validationSchema,
    onSubmit: ({ properties, name, description }) => {
      if (isEdit()) {
        updateWidget({
          values: {
            ...properties,
            settingsMinimum: typeof properties.settingsMinimum === "string" ? 0 : properties.settingsMinimum,
            settingsMaximum: typeof properties.settingsMaximum === "string" ? 100 : properties.settingsMaximum,
          },
          defaultValues: { ...getPrevValues() },
          id: props.widget.id,
          name,
          description,
          cb: submit,
        });
      } else {
        createWidgetFn({
          values: properties,
          name,
          description,
          cb: submit,
        });
      }
    },
  });

  const isLineChart = () => {
    return formik.values.properties.chartChartType === TypeOptionsEnum.line;
  };

  const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    formik.setFieldValue(e.target.name, checked).then(() => {});
  };

  useEffect(() => {
    if (isEdit()) {
      dispatchBus("@@board/SAVE_BOARD");
    }
  }, []);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={isEdit() ? "Edit property history table" : "Add property history table"}
        handleClose={reject}
        loading={isLoading}
        buttons={
          <>
            <Button data-test="close-chart" color="inherit" onClick={reject}>
              {msg.addWidgetModal.buttonCancel}
            </Button>
            <Button
              disabled={isLoading}
              data-test="save-regular-chart"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {isEdit() ? "Save" : "Add"}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          {isEdit() && (
            <>
              <Grid item>
                <CustomInput
                  name="name"
                  label="Name"
                  clearFieldIcon={true}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.name)}
                  helperText={formik.errors.name}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Appearance
            </Typography>
          </Grid>
          <Grid item>
            <CustomSelect
              name="properties.settingsStyle"
              label="Style"
              list={SETTINGS_STYLE_OPTIONS}
              value={formik.values.properties.settingsStyle}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              name="properties.settingsFormat"
              label="Format"
              list={SETTINGS_STATIC_TABLE_FORMAT}
              value={formik.values.properties.settingsFormat}
              onChange={formik.handleChange}
            />
          </Grid>
          <Collapse
            collapsedSize={0}
            in={formik.values.properties.settingsFormat !== SettingsFormatStaticTable.table}
            sx={{ marginLeft: "16px", marginTop: "16px" }}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <CustomSelect
                  name="properties.settingsChartSize"
                  label="Size"
                  list={SETTINGS_SIZE_OPTIONS}
                  value={formik.values.properties.settingsChartSize}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item>
                <CustomSelect
                  name="properties.chartChartType"
                  label="Chart type"
                  list={CHART_TYPE_OPTIONS}
                  value={formik.values.properties.chartChartType}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Collapse collapsedSize={0} in={isLineChart()} sx={{ marginLeft: "16px", marginTop: "16px" }}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <CustomSelect
                      name="properties.chartSmoothType"
                      label="Smooth type"
                      list={SMOOTH_TYPE_OPTIONS}
                      value={formik.values.properties.chartSmoothType}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <CustomSelect
                      name="properties.chartLineWidth"
                      label="Line width"
                      list={LINE_WIDTH_OPTIONS}
                      value={formik.values.properties.chartLineWidth}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
              </Collapse>

              <Grid item>
                <CustomSelect
                  name="properties.settingsYaxisScale"
                  label="Y-axis scale"
                  list={SCALE_OPTIONS}
                  value={formik.values.properties.settingsYaxisScale}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Collapse
                collapsedSize={0}
                in={formik.values.properties.settingsYaxisScale === ScaleOptionsEnum.fixed}
                sx={{ marginLeft: "16px", marginTop: "16px" }}
              >
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="subtitle2" color="#686868">
                      Axis boundaries
                    </Typography>
                  </Grid>

                  <Grid item container spacing={2}>
                    <Grid item xs={6} alignContent="flex-end">
                      <CustomInput
                        type="number"
                        name="properties.settingsMinimum"
                        label="Min"
                        clearFieldIcon={true}
                        value={formik.values.properties.settingsMinimum}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.properties?.settingsMinimum)}
                        helperText={formik.errors.properties?.settingsMinimum}
                      />
                    </Grid>
                    <Grid item xs={6} alignContent="flex-end">
                      <CustomInput
                        type="number"
                        name="properties.settingsMaximum"
                        label="Max"
                        clearFieldIcon={true}
                        error={Boolean(formik.errors.properties?.settingsMaximum)}
                        helperText={formik.errors.properties?.settingsMaximum}
                        value={formik.values.properties.settingsMaximum}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Collapse>
          <Grid item container justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="properties.settingsShowTitle"
              label="Title"
              value={formik.values.properties.settingsShowTitle}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center">
            <CustomSwitch
              disabled
              name="properties.settingsShowSubtitle"
              label="Subtitle"
              value={formik.values.properties.settingsShowSubtitle}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="properties.settingsShowRefreshButtons"
              label="On-widget configuration"
              value={formik.values.properties.settingsShowRefreshButtons}
              onChange={formik.handleChange}
            />
          </Grid>
          <Collapse in={formik.values.properties.settingsShowRefreshButtons}>
            <FormControl sx={{ marginLeft: "24px" }} component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="properties.settingsWidgetControls.propertySelect"
                      checked={formik.values.properties.settingsWidgetControls.propertySelect}
                      onChange={onChangeCheckbox}
                    />
                  }
                  label="Property"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="properties.settingsWidgetControls.monitorGroup"
                      checked={formik.values.properties.settingsWidgetControls.monitorGroup}
                      onChange={onChangeCheckbox}
                    />
                  }
                  label="Monitor group"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="properties.settingsWidgetControls.geotagSelect"
                      checked={formik.values.properties.settingsWidgetControls.geotagSelect}
                      onChange={onChangeCheckbox}
                    />
                  }
                  label="Geotag"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="properties.settingsWidgetControls.intervalRange"
                      checked={formik.values.properties.settingsWidgetControls.intervalRange}
                      onChange={onChangeCheckbox}
                    />
                  }
                  label="Date"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="properties.settingsWidgetControls.datePresets"
                      checked={formik.values.properties.settingsWidgetControls.datePresets}
                      onChange={onChangeCheckbox}
                    />
                  }
                  label="Date presets"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="properties.settingsWidgetControls.aggregationFunctionSelect"
                      checked={formik.values.properties.settingsWidgetControls.aggregationFunctionSelect}
                      onChange={onChangeCheckbox}
                    />
                  }
                  label="Aggregation"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="properties.settingsWidgetControls.csvButton"
                      checked={formik.values.properties.settingsWidgetControls.csvButton}
                      onChange={onChangeCheckbox}
                    />
                  }
                  label="Download CSV"
                />
              </FormGroup>
            </FormControl>
          </Collapse>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Description
            </Typography>
          </Grid>
          <Grid item>
            <CustomInput
              name="description"
              label={msg.addWidgetModal.description}
              clearFieldIcon={true}
              multiline={true}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(CreatePropertyHistoryTable);
