import { Button } from "@mui/material";
import { msg } from "../../messages";
import ConfirmationModal from "./ConfirmationModal";
import { create } from "react-modal-promise";

const ConfirmRpcExecution = (props) => {

  const submit = () => props.onResolve();

  const handleClose = () => submit();

  return (
    <>
      <ConfirmationModal
        modalOpen={props.isOpen}
        title={msg.deleteGroupModal.deleteGroup}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => {
              props.handleSave();
              submit();
            }}>
              Confirm
            </Button>
          </>
        }
      >
        {props.text}
      </ConfirmationModal>
    </>
  );
};

export default create(ConfirmRpcExecution);
