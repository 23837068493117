import { format, parseISO } from "date-fns";

const updatedBy = (updatedAt, userByBy) => {
  let date = format(parseISO(updatedAt), "MMM d, hh:mm:ss a");

  if (userByBy) {
    date += ` by ${userByBy?.login || ''}`
  }

  return date;
};

export {
  updatedBy
}
