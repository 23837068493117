import { gql, useLazyQuery } from "@apollo/client";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ClickAwayListener, Grow, Paper, Popper } from "@mui/material";
import Badge from "@mui/material/Badge";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";
import { format, parseISO } from "date-fns";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NOTIFICATION_TYPES } from "../../../constants";
import { msg } from "../../../messages";
import getNotificationType from "../../../utils/getNotificationType";
import { CHANNELS } from "../../../utils/notificationProfiles";

const notificationsDeliveries = gql`
  query fetchNotificationsDeliveries(
    $first: Int = 30
    $after: Cursor
    $condition: NotificationDeliveryCondition
  ) {
    notificationDeliveriesConnection(
      first: $first
      after: $after
      orderBy: CREATED_AT_DESC
      condition: $condition
    ) {
      nodes {
        id
        message
        createdAt
        user
        userLogin
        notification {
          id
          message
          createdAt
          userByBy {
            id
            login
            mName
          }
          byName
          tags
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

const listenNotifications = gql`
  subscription listenNotifications(
    $deliveryUser: [UUID]
    $deliveryPath: [String]
  ) {
    Notifications(
      filterA: { deliveryUser: $deliveryUser, deliveryPath: $deliveryPath }
    ) {
      event
      relatedNode {
        ... on NotificationDelivery {
          id
          message
          createdAt
          user
          userLogin
          notification {
            id
            message
            createdAt
            userByBy {
              id
              login
              mName
            }
            byName
            tags
          }
        }
      }
    }
  }
`;

const NotificationsModal = () => {
  const [open, setOpen] = useState(false);
  const [isNewNotifications, setIsNewNotifications] = useState(false);
  const anchorRef = useRef(null);

  const audio = new Audio("/notify.mp3");

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleOpen = () => {
    setValue("all");
    setIsNewNotifications(false);
    setOpen(true);
  };

  const theme = useTheme();

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: 0,
    },
    listItemIcon: {
      minWidth: "40px",
    },
    tabButton: {
      minWidth: "40px",
      width: "40px",
      paddingBottom: "15px",
    },
    tabPanel: {
      padding: "0",
      maxHeight: "300px",
      overflowY: "auto",
    },
    dot: {
      top: "4px",
      right: "4px",
      backgroundColor: "#D50000",
    },
  }));

  const classes = useStyles();
  const [value, setValue] = useState("all");

  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const notificationsFilter = () => {
    if (!data) return [];

    const list = data.notificationDeliveriesConnection.nodes.map((item) => ({
      ...item.notification,
      id: item.id,
      type: getNotificationType(item.notification?.tags || []),
    }));

    if (value === "all") {
      return list;
    } else if (value === "alert") {
      return list.filter(
        (item) =>
          item.type === NOTIFICATION_TYPES.ALERT ||
          item.type === NOTIFICATION_TYPES.ALERT_TRIGGERED
      );
    } else if (value === "notice") {
      return list.filter((item) => item.type === NOTIFICATION_TYPES.NOTICE);
    } else if (value === "message") {
      return list.filter((item) => item.type === NOTIFICATION_TYPES.MESSAGE);
    }
  };

  const user = useSelector((state) => state.user);
  const profile = useSelector((state) => state.profile);

  const [loadNotifications, { subscribeToMore, loading, error, called, data }] =
    useLazyQuery(notificationsDeliveries, {
      variables: {
        condition: {
          user: user?.id,
          deliveryPath: "PixelBoard",
        },
      },
      onCompleted: () => {},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });

  const getInAppProfile = () => {
    if (!user) {
      return false;
    }

    const targetProfile = user.userProfiles[0]?.profile.objectsToObjectsByObject1Id?.find(item => item.object2.deliveryChannel === CHANNELS.IN_APP);

    if (!targetProfile) {
      return false;
    }

    return targetProfile?.object2?.objectProperties?.find(item => item.key === 'settingsSoundInTheApp').value
  }

  useEffect(() => {
    if (user && open) {
      loadNotifications();
    }
  }, [user, open]);

  useEffect(() => {
    if (user) {
      if (!getInAppProfile()) {
        audio.pause();
        audio.currentTime = 0;
      }

      return subscribeToMore({
        document: listenNotifications,
        variables: {
          deliveryUser: user?.id,
          deliveryPath: "PixelBoard",
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (subscriptionData.data.Notifications.relatedNode?.id) {
            setIsNewNotifications(true);

            if (getInAppProfile()) {
              audio.play();
            }
          }
          return subscriptionData;
        },
      });
    }
  }, [user, profile]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const icon = (notification) => {
    const tags = notification?.tags || [];
    if (tags.find((obj) => obj === "triggered")) {
      return <NotificationsActiveIcon style={{ color: theme.palette.red }} />;
    } else if (tags.find((obj) => obj === "alert")) {
      return <NotificationsActiveIcon />;
    } else if (tags.find((obj) => obj === "notice")) {
      return <VolumeUpIcon />;
    } else if (tags.find((obj) => obj === "message")) {
      return <MessageIcon />;
    }
  };

  const spinner = () => {
    return (
      <ListItem
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "72px",
        }}
      >
        <CircularProgress size={25} />
      </ListItem>
    );
  };

  const empty = () => {
    return (
      <ListItem style={{ height: "72px" }}>
        <Typography
          variant="body1"
          style={{ width: "100%", textAlign: "center" }}
        >
          No more notifications
        </Typography>
      </ListItem>
    );
  };

  const Notifications = (props) => (
    <List disablePadding={true}>
      {props.isLoading && spinner()}
      {!props.isLoading && !notificationsFilter().length && empty()}
      {!props.isLoading &&
        notificationsFilter().map((notification) => (
          <ListItem
            key={notification.id}
            alignItems="flex-start"
            button
            onClick={() => {}}
            data-test-notification={notification.id}
          >
            <ListItemIcon className={classes.listItemIcon}>
              {icon(notification)}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1">
                  {notification.message}
                </Typography>
              }
              secondary={
                <span>
                  {format(
                    parseISO(notification.createdAt),
                    "MMM d, hh:mm:ss a"
                  ).toString() +
                    " by " +
                    notification.userByBy.login}
                </span>
              }
            />
          </ListItem>
        ))}
    </List>
  );
  return (
    <Fragment>
      {!isSm && (
        <Fab
          size="small"
          style={{
            marginLeft: "16px",
          }}
          aria-label="notifications"
          ref={anchorRef}
          onClick={() => handleOpen()}
        >
          <Badge
            {...(isNewNotifications ? { variant: "dot" } : {})}
            classes={{ dot: classes.dot }}
            color="error"
          >
            <NotificationsIcon />
          </Badge>
        </Fab>
      )}
      {isSm && (
        <IconButton
          aria-label="notifications"
          ref={anchorRef}
          onClick={() => handleOpen()}
          size="small"
        >
          <Badge
            {...(isNewNotifications ? { variant: "dot" } : {})}
            color="error"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      )}
      <Popper
        open={open}
        style={{ zIndex: 1, width: isSm ? "100%" : "auto" }}
        anchorEl={anchorRef.current}
        disablePortal={true}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [isSm ? 8 : 0, 8],
            },
          },
        ]}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper elevation={8}>
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  style={{ width: isSm ? "100%" : "412px", paddingTop: "9px" }}
                >
                  <TabContext value={value}>
                    <TabList
                      onChange={handleChangeTab}
                      aria-label="tabs"
                      variant="fullWidth"
                      TabIndicatorProps={{
                        style: { background: theme.palette.blue },
                      }}
                    >
                      <Tab
                        label={msg.notificationsModal.all}
                        className={classes.tabButton}
                        aria-label="all tab"
                        value="all"
                      />
                      <Tab
                        icon={<NotificationsActiveIcon />}
                        className={classes.tabButton}
                        aria-label="alert tab"
                        value="alert"
                      />
                      <Tab
                        icon={<VolumeUpIcon />}
                        className={classes.tabButton}
                        aria-label="notice tab"
                        value="notice"
                      />
                      <Tab
                        icon={<MessageIcon />}
                        className={classes.tabButton}
                        aria-label="message tab"
                        value="message"
                      />
                    </TabList>
                    <Divider style={{ position: "relative", top: "-1px" }} />
                    <TabPanel value="all" className={classes.tabPanel}>
                      <Notifications isLoading={loading} />
                    </TabPanel>
                    <TabPanel value="alert" className={classes.tabPanel}>
                      <Notifications isLoading={loading} />
                    </TabPanel>
                    <TabPanel value="notice" className={classes.tabPanel}>
                      <Notifications isLoading={loading} />
                    </TabPanel>
                    <TabPanel value="message" className={classes.tabPanel}>
                      <Notifications isLoading={loading} />
                    </TabPanel>
                  </TabContext>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default NotificationsModal;
