import { gql, useMutation } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import EventIcon from "@mui/icons-material/Event";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, ListItemSecondaryAction } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, { Suspense, useEffect, useState } from "react";
import ParameterDateRange from "../basic/ParameterDateRange";
import ParameterObjectModal from "./modals/ParameterObjectModal";
import RpcSubscribeWrapper from "../basic/RpcSubscribeWrapper";
import AccessSection from "../basic/AccessSection";
import ServiceSection from "../basic/ServiceSection";
import DescriptionSection from "../basic/DescriptionSection";
import ExtensionIcon from "@mui/icons-material/Extension";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import SelectType from "./modals/SelectType";
import HumanReadableProperty from "../geo-timer/HumanReadableProperty";
import MinimumDurationModal from "./modals/MinimumDurationModal";
import ClockOutline from "../../icons/clockOutline";

const UPDATE_PROPERTIES_BY_IDS = gql`
    mutation updateObjectPropertiesById($input: UpdateObjectPropertiesByIdInput!) {
        updateObjectPropertiesById(input: $input) {
            clientMutationId
        }
    }
`;

const CREATE_EXECUTION = gql`
    mutation createControlsExecution($input: CreateControlExecutionInput!) {
        createControlExecution(input: $input) {
            clientMutationId
            controlExecution {
                id
            }
        }
    }
`;

const UPDATE_PROPERTY = gql`
    mutation updateProperty($input: UpdateObjectPropertyInput!) {
        updateObjectProperty(input: $input) {
            clientMutationId
        }
    }
`;

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 1,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const GeotagsTableGeneral = (props) => {
  const { item } = props;
  const [createExecution, { loading }] = useMutation(CREATE_EXECUTION);
  const [updateProperty] = useMutation(UPDATE_PROPERTY);
  const [updateProperties] = useMutation(UPDATE_PROPERTIES_BY_IDS);

  const classes = useStyles({
    listItem: {
      minHeight: "56px",
    },
  });

  const getProperty = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };

  const settingsDateRange = () => {
    return item.objectProperties.find((item) => item.key === "settingsDate");
  };

  const settingsMinimumDuration = () => {
    return item.objectProperties.find((item) => item.key === "settingsMinimumDuration");
  };

  const [isMayGetData, setIsMayGetData] = useState(false);

  useEffect(() => {
    if (!settingsDateRange().value.value || !getProperty("settingsObject").value?.value) {
      setIsMayGetData(false);
    } else {
      setIsMayGetData(true);
    }
  }, [item]);


  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: "UpdateReport",
            objectId: item.id,
            params: {
              object: getProperty("settingsObject").value.value,
              range: getProperty("settingsDate").value.value,
              minimum_duration: getProperty("settingsMinimumDuration").value,
            },
          },
        },
      },
    });
  };


  const settingsType = () => {

    const schemaId = getProperty("settingsType")?.value;

    return {
      query: gql`
          query getSchema($id: UUID!) {
              schema(id: $id) {
                  id
                  name
              }
          }
      `,
      config: {
        variables: {
          id: schemaId,
        },
      },
    };
  };

  return (
    <>
      <List>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Parameters</Typography>
        </ListSubheader>
        <ListItem
          classes={{ container: classes.itemToHover }}
          style={{ height: "48px" }}
        >
          <ListItemIcon>
            <SubtitlesIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">
              <span>Type: </span>
              {getProperty("settingsType").value && (
                <>
                  <Suspense fallback={"Loading..."}>
                    <HumanReadableProperty
                      payload={settingsType("settingsType")}
                      getValue={(data) => data?.schema?.name || "n/a"}
                    ></HumanReadableProperty>
                  </Suspense>
                </>
              )}
              {!getProperty("settingsType")?.value && "n/a"}

            </Typography>}
            onClick={(e) => {
            }}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                SelectType({
                  handleSave: (id, value) => {
                    return updateProperties({
                      variables: {
                        input: {
                          propertiesArray: [
                            {
                              propertyId: getProperty("settingsType").id,
                              value: value,
                            },
                            {
                              propertyId: getProperty("settingsObject").id,
                              value: { title: "n/a", value: null },
                            },
                            {
                              propertyId: getProperty("valueValue").id,
                              value: [],
                            },
                          ],
                        },
                      },
                    });
                  },
                  id: getProperty("settingsType").id,
                  value: getProperty("settingsType").value,
                })
                  .then()
                  .catch();
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem
          classes={{ container: classes.itemToHover }}
          style={{ height: "48px" }}
        >
          <ListItemIcon>
            <ExtensionIcon />
          </ListItemIcon>
          <ListItemText title={getProperty("settingsObject")?.value?.title || "n/a"}>
            <Typography
              noWrap
              variant="body1"
            >
              Object: {getProperty("settingsObject")?.value?.title || "n/a"}
            </Typography>
          </ListItemText>
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={() => {
                const target = getProperty("settingsObject");

                ParameterObjectModal({
                  type: getProperty("settingsType").value,
                  value: target?.value?.value,
                  save: (object) => {
                    updateProperties({
                      variables: {
                        input: {
                          propertiesArray: [
                            {
                              propertyId: target.id,
                              value: object,
                            },
                            {
                              propertyId: getProperty("valueValue").id,
                              value: [],
                            },
                          ],
                        },
                      },
                    }).then(() => {
                    });
                  },
                })
                  .then()
                  .catch(() => {
                  });
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: classes.itemToHover,
          }}
          style={{ height: "48px", paddingRight: "96px" }}
        >
          <ListItemIcon>
            <EventIcon></EventIcon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                Date: {settingsDateRange().value.title}
              </Typography>
            }
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              onClick={() => {
                ParameterDateRange({
                  selected: settingsDateRange(),
                  save: (date) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: settingsDateRange().id,
                          patch: {
                            value: date,
                          },
                        },
                      },
                    }).then(() => {
                    });
                  },
                })
                  .then()
                  .catch(() => {
                  });
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: classes.itemToHover,
          }}
          style={{ height: "48px", paddingRight: "96px" }}
        >
          <ListItemIcon>
            <ClockOutline></ClockOutline>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                Minimum duration: {settingsMinimumDuration().value} sec
              </Typography>
            }
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              onClick={() => {
                MinimumDurationModal({
                  value: settingsMinimumDuration()?.value,
                  handleSave: (value) => updateProperty({
                    variables: {
                      input: {
                        id: settingsMinimumDuration().id,
                        patch: {
                          value,
                        },
                      },
                    },
                  }),
                });
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>


        <Box m={1} mb={4}>
          <RpcSubscribeWrapper
            rpcName={"UpdateReport"}
            objectId={item.id}
            disabled={!isMayGetData}
            object={item}
            title={"Update table"}
            handler={rpcHandler}
          >
            <LoadingButton fullWidth={true} variant={"outlined"}></LoadingButton>
          </RpcSubscribeWrapper>
        </Box>

        <ServiceSection classes={classes} item={item} />

        <AccessSection classes={classes} item={item} />

        {item.description && <DescriptionSection classes={classes} item={item} />}
      </List>
    </>
  );
};

export default GeotagsTableGeneral;
