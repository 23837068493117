import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SideListWrapper from "./components/common/SideListWrapper";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import SideCard from "./components/side-card/SideCard";
import MainLayout from "./MainLayout";
import { msg } from "./messages";
import CollectionsRoot from "./modules/collections/pages/CollectionsRoot";
import SideListBoards from "./modules/dashboards/components/SideListBoards";
import BoardsRoot from "./modules/dashboards/pages/BoardsRoot";
import Report from "./modules/reports/components/Report";
import SideListReports from "./modules/reports/components/SideListReports";
import ReportsRoot from "./modules/reports/pages/ReportsRoot";

const WIDGET_COLORS = {
  gray3: "#333333", // for CustomInputColor in EditWidgetChartModal
  wWhite: "#333333",
  wBlack: "#000",
  wTransparent: "rgba(255, 255, 255, 0)",
  wRed: "#d50000",
  wDefault: "#333333",
  wBlue: "#2780e3",
  wGreen: "#2e7d32",
  wYellow: "#ffd600",
  wOrange: "#ff7518",
  chartColors: {
    default: "#ffffff",
    gray: "#333333",
    green: "#2E7D32",
    orange: "#FF7518",
    red: "#D50000",
  },
}

const themeLight = createTheme({
  palette: {
    mode: "light",
    foreground: "#000", // text in forms (in some cases like autofill fix)
    background: {
      default: "#ffffff",
      modals: "#ffffff",
    },
    primary: {
      main: "#2780E3", //same as blue
    },
    secondary: {
      main: "#333333",
    },
    error: {
      main: "#D50000",
    },
    black1: "#333333",
    blue: "#2780e3", // light blue. links. modals headlines and subheadlines ("secondary")
    green: "#2E7D32",
    yellow: "#FFD600",
    orange: "#FF7518",
    red: "#D50000", // bells
    white: "#fff",
    black: "#000",
    gray1: "#686868", // subtitles, sidemenu items
    gray2: "#9E9E9E", // form helpers
    gray3: "#333333", // for CustomInputColor in EditWidgetChartModal
    gray4: "#616161", // for CustomInputColor
    inactiveIcon: "#eee",
    label: "#9E9E9E", // same as MuiFormLabel
    search: "#000", // search text in MainToolbar

    wWhite: "#fff",
    wBlack: "#000",
    wTransparent: "rgba(255, 255, 255, 0)", //0.5

    wRed: "#d50000", // widget color
    wDefault: "#333333",
    wBlue: "#2780e3", // widget color
    wGreen: "#2e7d32", // widget color
    wYellow: "#ffd600", // widget color
    wYellowGreen: "#C0CA33", // widget color
    wDeepRed: "#920000", // widget color
    wOrange: "#ff7518", // widget color
    chartColors: {
      default: "#ffffff",
      gray: "#333333",
      green: "#2E7D32",
      orange: "#FF7518",
      red: "#D50000",
    },
  },
});

const themeDark = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#2780E3", //same as blue
    },
    // foreground: "#fff", // text in forms (in some cases like autofill fix)
    // background: {
    //   default: "#000",
    //   modals: "#424242",
    // },
    // primary: {
    //   main: "#2780E3", // same as blue
    // },
    // secondary: {
    //   main: "#D50000",
    // },
    // black1: "#333333",
    // blue: "#2780E3", // light blue. links. modals headlines and subheadlines ("secondary")
    // green: "#2E7D32",
    // yellow: "#FFD600",
    // orange: "#FF7518",
    // red: "#D50000", // bells
    // white: "#666",
    // black: "#ddd",
    // gray1: "#ddd", // subtitles, sidemenu items
    ...WIDGET_COLORS
  },
});

const ENTITY_TYPE = {
  REPORT: "REPORT",
  BOARD: "BOARD",
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/boards/",
        element: <BoardsRoot />,
        handle: {
          type: ENTITY_TYPE.BOARD,
        },
        children: [
          {
            path: ":dashboardId",
            element: (
              <>
                <SideListWrapper>
                  <SideListBoards></SideListBoards>
                </SideListWrapper>
                <Dashboard />
              </>
            ),
          },
          {
            path: ":dashboardId/:groupId?/:widgetId?/:objectId?",
            element: (
              <>
                <SideListWrapper>
                  <SideCard></SideCard>
                </SideListWrapper>

                <Dashboard />
              </>
            ),
          },
        ],
      },
      {
        path: "/reports/",
        element: <ReportsRoot />,
        children: [
          {
            path: ":reportId",
            element: (
              <>
                <SideListWrapper>
                  <SideListReports></SideListReports>
                </SideListWrapper>
                <Report />
              </>
            ),
          },
          {
            path: ":reportId/:groupId?/:widgetId?/:objectId?",
            element: (
              <>
                <SideListWrapper>
                  <SideCard></SideCard>
                </SideListWrapper>

                <Report />
              </>
            ),
          },
        ],
      },
      {
        path: "/collections/*",
        element: <CollectionsRoot />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/from-app",
    element: <Login fromApp={true} />,
  },
  {
    path: "/view",
    element: <Login view={true} />,
  },
]);

export default function AppWrapper(props) {
  const theme = useSelector((state) => state?.profile?.programTheme || 'classic');
  const lang = useSelector((state) => state.settings.lang);

  let root = document.documentElement;

  if (theme === 'dark') {

    root.style.setProperty('--card-thumbnail-bg', '#333');
    root.style.setProperty('--card-thumbnail-fg', '#1D1D1D');
  } else {

    root.style.setProperty('--card-thumbnail-bg', '#DBDBDB');
    root.style.setProperty('--card-thumbnail-fg', 'white');
  }

  msg.setLanguage(lang);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme === "dark" ? themeDark : themeLight}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
