import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { loader } from "graphql.macro";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import CommonModal from "../../components/CommonModal";
import CustomInput from "../../components/CustomInput";
import { msg } from "../../messages";
import { DASHBOARDS_PROPS_QUERY } from "../../queries";
import { setSettings } from "../../store/settingsSlice";
import CustomAutocomplete from "./../CustomAutocomplete";
import useCustomNavigate from "./../hooks/useCustomNavigate";
import SelectColor from "../forms/SelectColor";
import CustomSelect from "../CustomSelect";
import { BOARD_BG_OPTIONS, DASHBOARD_DEFAULT_FORM, TITLE_STYLE } from "./constants";
import RpcSubscribeWrapper from "../side-card/static-table/RpcSubscribeWrapper";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteDashboardModal from "../DeleteDashboardModal";
import { SignJWT } from "jose";
import useHandleCopy from "../side-card/menu/handlers/useHandleCopy";
import ImageItem from "../common/image-item/ImageItem";
import useMedia from "../../utils/useMedia";

const DASHBOARD_QUERY = gql`
    query getDashboard($objId: UUID!) {
        object(id: $objId) {
            id
            name
            description
            schemaName
            enabled
            muted
            readergroup
            usergroup
            editorgroup
            collections: objectsToObjectsByObject1Id(filter: { object2: {schemaTags: { contains: "collection" }} }) {
                id
                target: object2 {
                    name
                    id
                }
            }
            objectProperties(sort: { sortBy: { field: PROPERTY } }) {
                groupName
                property
                spec {
                    units
                    description
                    type {
                        name
                    }
                    valueSet
                }
                key
                value
            }
        }
    }
`;

const CREATE_EXECUTION = gql`
    mutation createControlsExecution($input: CreateControlExecutionInput!) {
        createControlExecution(input: $input) {
            controlExecution {
                id
            }
            clientMutationId
        }
    }
`;

const LOAD_GROUP = gql`
    query loadGroups {
        userGroups {
            id
            isSystem
            groupName
            description
        }
    }
`;

const DASHBOARDS_QUERY = loader("../../graphql/DashboardsQuery.graphql");
const COLLECTIONS_QUERY = loader("../../modules/collections/api/CollectionsQuery.graphql");
const COLLECTIONS_QUERY_FULL = loader("../../graphql/CollectionsQuery.graphql");
const LINK_OBJECTS = loader("../../graphql/LinkObjects.graphql");
const UNLINK_OBJECT = loader("../../graphql/UnlinkObject.graphql");
const ADD_DASHBOARD = loader("../../graphql/AddDashboard.graphql");
const UPDATE_OBJECT_PROPERTY = loader("../../graphql/UpdateObjectWithProperties.graphql");

const DASHBOARDS_QUERY_WITHOUT_COLLECTIONS = gql(/* GraphQL */ `
  query getDashboardsWithoutCollections {
    dashboards: objects(
      filter: {
        schemaTags: { contains: ["application", "board", "dashboard"] }
        objectsToObjectsByObject1IdConnection: {
          every: { object2: { not: { schemaTags: { contains: ["collection"] } } } }
        }
      }
      orderBy: NAME_ASC
    ) {
      id
      name
    }
  }
`);

const AddDashboardModal = (props) => {
  const history = useCustomNavigate();
  const [linkObjects] = useMutation(LINK_OBJECTS);
  const [unlinkObjects] = useMutation(UNLINK_OBJECT);
  const [addDashboard] = useMutation(ADD_DASHBOARD);
  const [updateProperty] = useMutation(UPDATE_OBJECT_PROPERTY);
  const collectionsQuery = useQuery(COLLECTIONS_QUERY);
  const groupsQuery = useQuery(LOAD_GROUP);
  const { uploadMedia } = useMedia();

  const [createExecution] = useMutation(CREATE_EXECUTION);

  const [dashboardsLazyQuery] = useLazyQuery(DASHBOARDS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [dashboardsWithoutCollectionsLazyQuery] = useLazyQuery(DASHBOARDS_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: "network-only",
  });

  const [collectionsLazyQuery] = useLazyQuery(COLLECTIONS_QUERY_FULL, {
    fetchPolicy: "network-only",
  });

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: "CopyDashboard",
            objectId: props.dashboardId,
            params: {
              UUID: props.dashboardId,
              NAME: `${formik.values.name} copy`,
            },
          },
        },
      },
    });
  };

  const [name, setName] = useState("");

  let defaultValues = {
    ...DASHBOARD_DEFAULT_FORM
  };

  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);
  const [backgroundFileItem, setBackgroundFileItem] = useState(null);

  const validationSchema = yup.object({
    name: yup.string().trim().required("Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      name,
      generalTitle: null
    },
    validationSchema,
    onSubmit: async () => {
      if (props.isEdit) {
        await handleUpdateDashboard()
      } else {
        await handleAddDashboard();
      }
    },
  });

  const [dashboardsQueryProps] = useLazyQuery(DASHBOARDS_PROPS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const dashBoardsAmount = data.schemata[0].objectsCount;
      const addingZeroCondition = dashBoardsAmount <= 8 ? "0" : "";
      formik.setValues({
        name: `Dashboard #${addingZeroCondition}${dashBoardsAmount + 1}`,
      });
      setName(`Dashboard #${addingZeroCondition}${dashBoardsAmount + 1}`);
    },
  });

  const [dashboardLazyQuery, { data: dashboard }] = useLazyQuery(DASHBOARD_QUERY, {
    variables: {
      objId: props.dashboardId,
    },
    onCompleted: (data) => {
      const title = data.object.objectProperties.find(item => item.key === 'generalTitle').value;
      const backgroundImageId = data.object.objectProperties.find(item => item.key === 'generalBackgroundImageUid').value;
      const generalTitleStyle = data.object.objectProperties.find(item => item.key === 'generalTitleStyle').value;
      const generalBgColor = data.object.objectProperties.find(item => item.key === 'generalBgColor').value;
      setAccessRights({
        readerGroup: data.object.readergroup,
        userGroup: data.object.usergroup,
        editorGroup: data.object.editorgroup,
      });
      formik.setFieldValue("name", data.object.name ?? null);
      formik.setFieldValue("generalTitle", title ?? null);
      setCollection(data.object?.collections?.[0]?.target?.id || null)
      setValues({
        generalBgColor: generalBgColor,
        generalTitleStyle: generalTitleStyle,
        generalBackgroundImageUid: backgroundImageId ?? null
      });
      setDescription(data.object.description);
    },
    fetchPolicy: "network-only",
  });

  const [accessRights, setAccessRights] = useState({
    readerGroup: "",
    userGroup: "",
    editorGroup: "",
  });
  const [collection, setCollection] = useState("");

  const [description, setDescription] = useState("");

  const getCollections = () => {
    if (!collectionsQuery.loading && collectionsQuery.data) {
      return collectionsQuery.data.collections.map((item) => ({
        value: item.id,
        title: item.name,
      }));
    }
    return [];
  };

  const getGroups = () => {
    if (!groupsQuery.loading && groupsQuery.data) {
      return groupsQuery.data.userGroups.map((item) => ({
        value: item.id,
        title: item.groupName,
        disabled: item.groupName === "Nobody",
      }));
    }
    return [];
  };

  const afterCreate = (id) => {
    history(`/boards/${id}`);
    dispatch(setSettings({ isEditMode: true }));
    submit();
  };

  const handleAddDashboard = async () => {
    const generalBackgroundImageUid = await uploadImage();

    const payload = {
      values: [
        {
          propertyKey: "generalTitle",
          value: formik.values.generalTitle,
        },
        {
          propertyKey: "generalTitleStyle",
          value: values.generalTitleStyle,
        },
        {
          propertyKey: "generalBgColor",
          value: values.generalBgColor,
        },
        {
          propertyKey: "generalBackgroundImageUid",
          value: generalBackgroundImageUid || values.generalBackgroundImageUid,
        },
        {
          propertyKey: "generalBackgroundImageName",
          value: values.generalBackgroundImageName,
        },
      ],
      name: formik.values.name,
      description,
    };

    if (accessRights.editorGroup) {
      payload.editorGroup = accessRights.editorGroup;
    }

    if (accessRights.readerGroup) {
      payload.readerGroup = accessRights.readerGroup;
    }

    if (accessRights.userGroup) {
      payload.userGroup = accessRights.userGroup;
    }

    toast
      .promise(
        addDashboard({
          variables: payload,
        }),
        {
          loading: "Creating dashboard...",
          success: () => "Dashboard created",
          error: (err) => `${err.toString()}`,
        }
      )
      .then(async ({ data }) => {
        if (collection) {
          const variables = {
            widgetId: data.createObjectWithProperties.uuid,
            objectId: collection,
          };

          await linkObjects({ variables });
          await collectionsLazyQuery();
          await dashboardsLazyQuery();
          afterCreate(data.createObjectWithProperties.uuid);
        } else {
          await dashboardsLazyQuery();
          await dashboardsWithoutCollectionsLazyQuery();
          afterCreate(data.createObjectWithProperties.uuid);
        }
      });
  };

  const uploadImage = async () => {
    if (!backgroundFileItem) {
      return null;
    }

    try {
      const { data } = await uploadMedia(backgroundFileItem);
      return data;
    } catch (e) {
      return null;
    }
  };

  const handleUpdateDashboard = async () => {
    const generalBackgroundImageUid = await uploadImage();

    const payload = {
      objectId: props.dashboardId,
      keyedProperties: [
        {
          propertyKey: "generalTitle",
          value: formik.values.generalTitle,
        },
        {
          propertyKey: "generalTitleStyle",
          value: values.generalTitleStyle,
        },
        {
          propertyKey: "generalBgColor",
          value: values.generalBgColor,
        },
        {
          propertyKey: "generalBackgroundImageUid",
          value: generalBackgroundImageUid || values.generalBackgroundImageUid,
        },
        {
          propertyKey: "generalBackgroundImageName",
          value: values.generalBackgroundImageName,
        },
      ],
      name: formik.values.name,
      description,
    };

    if (accessRights.editorGroup) {
      payload.editorgroup = accessRights.editorGroup;
    }

    if (accessRights.readerGroup) {
      payload.readergroup = accessRights.readerGroup;
    }

    if (accessRights.userGroup) {
      payload.usergroup = accessRights.userGroup;
    }

    toast
      .promise(
        updateProperty({
          variables: {
            input: {
              detailedObject: [payload],
            },
          },
        }),
        {
          loading: "Saving dashboard...",
          success: () => "Dashboard saved",
          error: (err) => `${err.toString()}`,
        }
      )
      .then(async () => {
        const currentCollection = dashboard?.object?.collections?.[0]?.target?.id;
        const currentCollectionLinkId = dashboard?.object?.collections?.[0]?.id;
        if (collection) {
          if (currentCollectionLinkId) {
            await unlinkObjects({
              variables: {
                linkId: currentCollectionLinkId,
              },
            })
          }
          await linkObjects({
            variables: {
              widgetId: dashboard.object.id,
              objectId: collection,
            }
          });
          await collectionsLazyQuery();
          await dashboardsLazyQuery();
          await dashboardsWithoutCollectionsLazyQuery();
        } else {
          if (currentCollectionLinkId) {
            await unlinkObjects({
              variables: {
                linkId: currentCollectionLinkId,
              },
            })
          }
          await collectionsLazyQuery();
          await dashboardsLazyQuery();
          await dashboardsWithoutCollectionsLazyQuery();
        }

        submit();
      });
  };

  const dispatch = useDispatch();
  const handleCopy = useHandleCopy();

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (checked) value = checked;

    setValues({ [name]: value });
  };

  const getLinkToView = async () => {
    const secret = new TextEncoder().encode(
      "cc7e0d44fd473002f1c42167459001140ec6389b7353f8088f4d9a95f2f596f2"
    );
    const alg = "HS256";

    const jwt = await new SignJWT({
      dashboardId: props.dashboardId,
      type: "board",
      token: localStorage.getItem("refreshToken"),
    })
      .setProtectedHeader({ alg })
      .setIssuedAt()
      .setExpirationTime("1y")
      .sign(secret);

    handleCopy({
      message: "Link to view copied",
      text: `${window.location.origin}/view/?token=${jwt}/?mode=0`,
    });
  };

  useEffect(() => {
    if (props.isEdit) {
      dashboardLazyQuery()
    } else {
      dashboardsQueryProps()
    }
  }, [props.isEdit]);

  return (
    <>
      <CommonModal
        loading={dashboardsQueryProps.loading}
        modalOpen={props.isOpen}
        title={props.isEdit ? 'Edit dashboard' : 'Add dashboard'}
        forceTitle={true}
        contentStyles={{
          padding: "14px 16px 16px 14px",
        }}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>{msg.addDashboardModal.buttonCancel}</Button>
            <Button color="primary" data-test="createBoard" onClick={formik.handleSubmit}>
              { props.isEdit ? 'Save' : 'Add' }
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomInput
              shrink={Boolean(formik.values?.name)}
              name="name"
              label={msg.addDashboardModal.name}
              clearFieldIcon={true}
              value={formik.values?.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item>
            <CustomInput
              name="generalTitle"
              label="Title"
              clearFieldIcon={true}
              value={formik.values?.generalTitle}
              onChange={formik.handleChange}

            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={collectionsQuery.loading}
              label={"Collection"}
              list={getCollections()}
              value={collection}
              onChange={(e) => {
                setCollection(e.target.value);
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <CustomSelect
                name="generalTitleStyle"
                label="Title style"
                list={TITLE_STYLE}
                value={values.generalTitleStyle ?? "dark"}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectColor
                name="generalBgColor"
                label={"Background color"}
                value={values.generalBgColor ?? "#333333"}
                list={BOARD_BG_OPTIONS}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid item>
            <ImageItem
              onChange={(e) => {
                setValues({ generalBackgroundImageUid: e.target.value });
              }}
              onDelete={() => {
                setValues({ generalBackgroundImageUid: null });
              }}
              onSelectFile={(file) => {
                setBackgroundFileItem(file);
              }}
              id={values.generalBackgroundImageUid}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Access rights
            </Typography>
          </Grid>

          <Grid item>
            <CustomAutocomplete
              label={"Editors group"}
              disabled={groupsQuery.loading}
              list={getGroups()}
              value={accessRights.editorGroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  editorGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={groupsQuery.loading}
              label={"Users group"}
              list={getGroups()}
              value={accessRights.userGroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  userGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              label={"Readers group"}
              disabled={groupsQuery.loading}
              list={getGroups()}
              value={accessRights.readerGroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  readerGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Description
            </Typography>
          </Grid>

          <Grid item>
            <CustomInput
              name="description"
              label={msg.addDashboardModal.description}
              clearFieldIcon={true}
              value={description}
              multiline={true}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>

          { props.isEdit &&
            <>
             <Grid item container>
              <Button
                data-test="copyDashboardId"
                variant="outlined"
                color="primary"
                fullWidth
                disableElevation
                style={{ marginTop: "20px" }}
                onClick={(e) => {
                  handleCopy({
                    message: msg.editDashboardModal.copied,
                    text: props.dashboardId,
                  });
                }}
              >
                {msg.editDashboardModal.buttonCopy}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                disableElevation
                style={{ marginTop: "20px" }}
                onClick={getLinkToView}
              >
                Get link to view
              </Button>

              <RpcSubscribeWrapper
                rpcName={"CopyBoard"}
                objectId={props.dashboardId}
                object={null}
                handler={rpcHandler}
                title={"Copy Board"}
                successCb={(dashboardId) => {
                  if (dashboardId) {
                    window.location.href = `/boards/${dashboardId}`;
                  }
                }}
              >
                <LoadingButton
                  data-test-copy-board-rpc
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disableElevation
                  style={{ marginTop: "20px" }}
                ></LoadingButton>
              </RpcSubscribeWrapper>

              <Button
                data-test="deleteDashboard"
                variant="contained"
                color="error"
                fullWidth
                disableElevation
                style={{ marginTop: "16px" }}
                onClick={(e) => {
                  DeleteDashboardModal({
                    open: true,
                    dashboardId: props.dashboardId,
                    name: formik.values.name,
                  })
                    .then(() => {
                      submit();
                    })
                    .catch(() => {});
                }}
              >
                {msg.editDashboardModal.buttonDelete}
              </Button>
            </Grid>
            </>
          }
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(AddDashboardModal);
