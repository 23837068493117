import { DataboxIcon } from "./DataboxIcon";
import { styled } from "@mui/system";
import { SettingsSizeOptions } from "../../../utils/constants/selectOptions";
import { Typography } from "@mui/material";
import { FormatEnum } from "../../create-widget/databox/DataboxTypes";

const Wrap = styled("div")(() => ({
  "&": {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
  },
}));

const Title = styled(Typography)(({ size, widgetSize }) => ({
  "&": {
    fontSize: widgetSize === SettingsSizeOptions.medium ? "45px" : "24px",
    lineHeight: size.title,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "500",
    height: size.title,
    textAlign: "center",
    flexShrink: 0,
    padding: "0 6px",
  },
}));

const getValueFontSize = (size, mode) => {
  if (size === SettingsSizeOptions.medium && mode === FormatEnum.value) {
    return '56px'
  }

  if (size === SettingsSizeOptions.tiny && mode === FormatEnum.value) {
    return '24px'
  }

  if (size === SettingsSizeOptions.medium) {
    return '36px'
  }

  if (size === SettingsSizeOptions.tiny) {
    return '12px'
  }

  if (mode === FormatEnum.value) {
    return '32px'
  }

  return '20px'
}

const Value = styled(Typography)(({ size, mode, widgetSize }) => ({
  "&": {
    fontSize: getValueFontSize(widgetSize, mode),
    lineHeight: widgetSize === SettingsSizeOptions.tiny ? "12px" : "30px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "500",
    flexShrink: 0,
    padding: "0 6px",
    height: mode === FormatEnum.value ? '100%' : size.value,
    textAlign: "center",
    alignContent: mode === FormatEnum.value ? 'center' : undefined
  },
}));

const Label = styled(Typography)(({ size, widgetSize }) => ({
  "&": {
    fontSize: widgetSize === SettingsSizeOptions.medium ? "20px" : "16px",
    lineHeight: size,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "500",
    height: size,
    textAlign: "center",
    padding: "0 6px",
    flexShrink: 0,
  },
}));

const getIconPadding = (size) => {
  switch (size) {
    case SettingsSizeOptions.tiny:
      return '0'
    default:
      return '12px 12px 0 12px'
  }
}

const getPaddingAroundIcon = (size, mode) => {
  if (size === SettingsSizeOptions.tiny && mode === FormatEnum.icon) {
    return '8px 0 0 0'
  }

  if (size === SettingsSizeOptions.tiny) {
    return '4px 0 2px 0'
  }

  return  0
}

const getFixedSize = (size, mode) => {
  switch (size) {
    case SettingsSizeOptions.tiny:
      return {
        title: "96px",
        label: "48px",
        iconW: mode === FormatEnum.icon ? "32px" : "24px",
        iconH: mode === FormatEnum.icon ? "32px" : "24px",
        value: "12px",
      };
    case SettingsSizeOptions.medium:
      return {
        title: "96px",
        label: "48px",
        iconW: mode === FormatEnum.icon ? "192px" : "124px",
        iconH: mode === FormatEnum.icon ? "192px" : "124px",
        value: "72px",
      };
    default:
      return {
        title: "48px",
        label: "48px",
        iconW: mode === FormatEnum.icon ? "96px" : "72px",
        iconH: mode === FormatEnum.icon ? "96px" : "60px",
        value: "36px",
      };
  }
};

export const VerticalView = (
  {
    colors,
    iconId,
    mode,
    size,
    isShowTitle,
    label,
    title,
    value,
  }) => {

  const sizes = getFixedSize(size, mode);

  return (
    <Wrap style={{ heigth: sizes.iconW, color: colors?.fg || "transparent" }}>
      {(isShowTitle && mode !== FormatEnum.icon && size !== SettingsSizeOptions.tiny) &&
        <Title mode={mode} size={sizes} widgetSize={size}>
          {title}
        </Title>
      }

      {mode !== FormatEnum.value &&
        <div style={{
          display: "flex",
          justifyContent: "center",
          padding: getPaddingAroundIcon(size, mode)
        }}>
          <DataboxIcon
            padding={getIconPadding(size)}
            id={iconId} colors={colors}
            height={sizes.iconH}
            width={sizes.iconW} />
        </div>
      }

      {mode !== FormatEnum.icon && <Value mode={mode} widgetSize={size} size={sizes}>{value}</Value>}

      {(label && mode !== FormatEnum.icon && size !== SettingsSizeOptions.tiny) &&
        <Label mode={mode} size={sizes.label} widgetSize={size}>
          {label}
        </Label>
      }
    </Wrap>
  );
};
