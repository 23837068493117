import { gql, useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useDebounce } from "react-use";

import uniqBy from "lodash.uniqby";
import OnWidgetAutocomplete from "../../base/OnWidgetAutocomplete";

export const OBJECTS_QUERY = gql`
    query listDevices($filter: ObjectFilter, $first: Int) {
        objects(orderBy: NAME_ASC, filter: $filter, first: $first) {
            id
            name
        }
    }
`;

const SelectGroup = (props) => {
  const [objects, setObjects] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [debouncedValue, setDebouncedValue] = useState(null);

  const [objectsLoad, { loading, data }] = useLazyQuery(OBJECTS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (e) => {
      setObjects([...e.objects.map((item) => ({ value: item.id, title: item.name }))]);
    },
    variables: {
      first: 20,
    },
  });

  const handleOnOpen = () => {
    const filterExtraObject = {};
    const filter = {
      variables: {
        first: 20,
        filter: {
          schemaTags: {
            contains: ["application", "monitor", "group"],
          },
          enabled: {
            equalTo: true,
          },
        },
      },
    };

    if (props.value?.value) {
      filterExtraObject.id = {
        equalTo: props.value?.value,
      };
    } else {
      delete filterExtraObject.id;
    }

    // filter.variables.filter.or = [filterExtraObject];
    objectsLoad(filter);
  };

  useDebounce(
    () => {
      if (inputValue !== null) {
        setDebouncedValue(inputValue);
      }
    },
    500,
    [inputValue],
  );


  useEffect(() => {
    if (debouncedValue !== null && debouncedValue !== 0) {
      let filterExtraObject = [];
      const filter = {
        variables: {
          first: 20,
          filter: {
            or: [],
            schemaTags: {
              contains: ["application", "monitor", "group"],
            },
            enabled: {
              equalTo: true,
            },
            name: {
              includesInsensitive: debouncedValue,
            },
          },
        },
      };

      if (props.value?.value) {

        filterExtraObject = [
          {
            id: {
              equalTo: props.value?.value
            }
          },
          {
            name: {
              includesInsensitive: debouncedValue,
            },
          }
        ];
      } else {
        filterExtraObject = []
      }

      filter.variables.filter.or = filterExtraObject;

      objectsLoad(filter);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (!props.value) {

      props.handleChange(null);
    }
  }, [props.value]);

  const handleInputChange = (e) => {
    if (e && typeof e.currentTarget.value === "string") {
      setInputValue(e.currentTarget.value?.trim());
    }
  };

  const handleChange = (e) => {
    props.handleChange(e);
  };

  return (
    <OnWidgetAutocomplete
      options={objects}
      loading={loading}
      handleOnOpen={handleOnOpen}
      value={props.value}
      fgColor={props.fgColor}
      bgColor={props.bgColor}
      label={props.label}
      handleChange={handleChange}
      handleInputChange={handleInputChange}
    />
  );
};

export default SelectGroup;
