import { gql, useMutation } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import BlockIcon from "@mui/icons-material/Block";
import ContentCopyRounded from "@mui/icons-material/ContentCopyRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LinearProgress from "@mui/material/LinearProgress";
import { orderBy } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddGroupModal from "../../../components/AddGroupModal";
import DeleteGroupsModal from "../../../components/modals/DeleteGroupsModal";
import RpcProcessModal from "../../../components/modals/RpcProcessModal";
import SideList from "../../../components/SideList";
import AddDashboardModal from "../../../components/dashboard/AddDashboardModal";

const CREATE_EXECUTION = gql`
  mutation createControlsExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      controlExecution {
        id
      }
      clientMutationId
    }
  }
`;

const SideListBoards = ({ dashboardLoading, dashboardData }) => {
  let { dashboardId } = useParams();
  const [itemsChecked, setItemsChecked] = useState([]);
  const [createExecution, { loading: controlLoading }] =
    useMutation(CREATE_EXECUTION);
  const isMayEdit = useSelector((state) => state.settings.isMayEdit);

  const dashboardName = () => {
    if (!dashboardLoading && dashboardData?.dashboard) {
      return dashboardData.dashboard.name || "n/a";
    }

    return "Loading...";
  };

  const getList = (sortType) => {
    if (!dashboardLoading && dashboardData?.dashboard) {
      const groups = dashboardData.dashboard.groups.map((item) => item.group);
      return orderBy(groups, [sortType], ["asc"]).map((item) => ({
        ...item,
        link: `/boards/${dashboardData?.dashboard.id}/${item.id}`,
      }));
    }
    return [];
  };

  const headerMenu = [
    {
      icon: <AddIcon />,
      title: "Add new container",
      id: "add_new_group",
      disabled: !isMayEdit,
      handleAction: () => {
        AddGroupModal({})
          .then()
          .catch(() => {});
      },
    },
    { isDivider: true, name: "", id: "divider" },
    {
      icon: <EditIcon />,
      title: "Edit dashboard",
      id: "edit_dashboard",
      handleAction: () => {
        AddDashboardModal({
          isEdit: true,
          dashboardId,
        })
          .then()
          .finally();
      },
      disabled: false,
    },
    {
      icon: <ContentCopyRounded />,
      title: "Copy dashboard",
      id: "copy_dashboard",
      handleAction: () => {
        RpcProcessModal({
          objectId: dashboardId,
          successCb: (dashboardId) => {
            if (dashboardId) {
              window.location.href = `/boards/${dashboardId}`;
            }
          },
          callRpc: rpcHandler,
        }).then();
      },
    },
  ];

  const headerGroupMenu = [
    {
      icon: <BlockIcon />,
      title: "Disable",
      id: "disable",
      disabled: true,
    },
    {
      icon: <DeleteIcon />,
      title: "Delete",
      id: "delete-group",
      handleAction: () => {
        DeleteGroupsModal({
          ids: itemsChecked,
          dashboardId,
          setItemsChecked,
        })
          .then()
          .catch(() => {});
      },
      disabled: false,
    },
  ];

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: "CopyDashboard",
            objectId: dashboardId,
            params: {
              UUID: dashboardId,
              NAME: `${dashboardData.dashboard.name} copy`,
            },
          },
        },
      },
    });
  };

  return (
    <>
      {dashboardLoading && !dashboardData && <LinearProgress />}
      {!dashboardLoading && dashboardData && (
        <SideList
          sideBarWidth={412}
          title={dashboardName()}
          getList={getList}
          itemsChecked={itemsChecked}
          setItemsChecked={setItemsChecked}
          headerMenu={headerMenu}
          headerGroupMenu={headerGroupMenu}
        />
      )}
    </>
  );
};

export default SideListBoards;
