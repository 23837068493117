import { gql, useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import uniqBy from "lodash.uniqby";
import OnWidgetAutocomplete from "../../base/OnWidgetAutocomplete";
import { orderBy } from "lodash";

export const GET_SCHEMA_PROPERTIES = gql`
    query schema($schemaId: UUID!) {
        schema(id: $schemaId) {
            schemaProperties {
                id
                description
                groupName
                groupDescription
                units
            }
        }
    }
`;
const SelectProperty = (props) => {
  const [objects, setObjects] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [value, setValue] = useState(null);
  const [debouncedValue, setDebouncedValue] = useState(null);
  const [properties, setProperties] = useState([]);

  const [loadSchemaProperties, { loading }] = useLazyQuery(GET_SCHEMA_PROPERTIES, {
    onCompleted: (e) => {
      console.log(e);
      setProperties(
        orderBy(
          e.schema.schemaProperties.map((item) => {
            return {
              value: item.id,
              title: item.description || item.property,
            };
          }), ['title'],["asc"],
        ),
      );
    },
  });
  const handleOnOpen = () => {
    if (props.schemaId) {
      loadSchemaProperties({
        variables: {
          schemaId: props.schemaId,
        },
      });

    }
  };

  useDebounce(
    () => {
      if (inputValue !== null) {
        setDebouncedValue(inputValue);
      }
    },
    500,
    [inputValue],
  );

  // useEffect(() => {
  //   if (props.schemaId) {
  //     loadSchemaProperties({
  //       variables: {
  //         schemaId: props.schemaId,
  //       },
  //     });
  //   }
  // }, []);


  const handleInputChange = (e) => {
    if (e && typeof e.currentTarget.value === "string") {
      setInputValue(e.currentTarget.value?.trim());
    }
  };

  const handleChange = (e) => {
    setValue(e);
    props.handleChange(e);
  };

  return (
    <OnWidgetAutocomplete
      options={orderBy(properties, ['title'], ['asc'])}
      loading={loading}
      handleOnOpen={handleOnOpen}
      value={props.value}
      fgColor={props.fgColor}
      bgColor={props.bgColor}
      label={props.label}
      handleChange={handleChange}
      handleInputChange={handleInputChange}
    />
  );
};

export default SelectProperty;
