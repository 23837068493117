export enum SettingsStyleOptions {
  lightondark = "lightondark",
  lightontransparent = "lightontransparent",
  darkonlight = "darkonlight",
  darkontransparent = "darkontransparent",
}

export const SETTINGS_STYLE_OPTIONS = [
  {
    value: SettingsStyleOptions.lightondark,
    title: "Light on dark",
  },
  {
    value: SettingsStyleOptions.lightontransparent,
    title: "Light on transparent",
  },
  {
    value: SettingsStyleOptions.darkonlight,
    title: "Dark on light",
  },
  {
    value: SettingsStyleOptions.darkontransparent,
    title: "Dark on transparent",
  },
];

export enum SettingsSizeOptions {
  small = "small",
  medium = "medium",
  large = "large",
  tiny = "tiny",
}

export enum SettingsFormatStaticTable {
  chart = "chart",
  table = "table",
  chartAndTable = "chartAndTable",
}

export const SETTINGS_SIZE_OPTIONS = [
  {
    value: SettingsSizeOptions.small,
    title: "Small",
  },
  {
    value: SettingsSizeOptions.medium,
    title: "Medium",
  },
  {
    value: SettingsSizeOptions.large,
    title: "Large",
  },
];

export const SETTINGS_STATIC_TABLE_FORMAT = [
  {
    value: SettingsFormatStaticTable.table,
    title: "Table",
  },
  {
    value: SettingsFormatStaticTable.chart,
    title: "Chart",
  },
  {
    value: SettingsFormatStaticTable.chartAndTable,
    title: "Table & Chart",
  },
];

export enum TypeOptionsEnum {
  bar = "bar",
  line = "line",
  scatter = "scatter",
}

export const CHART_TYPE_OPTIONS = [
  {
    value: TypeOptionsEnum.bar,
    title: "Bar",
  },
  {
    value: TypeOptionsEnum.line,
    title: "Line",
  },
  {
    value: TypeOptionsEnum.scatter,
    title: "Point",
  },
];

export enum SmoothTypeEnum {
  smooth = "smooth",
  linear = "linear",
  stepwise = "stepwise",
}

export const SMOOTH_TYPE_OPTIONS = [
  {
    value: SmoothTypeEnum.smooth,
    title: "Smooth",
  },
  {
    value: SmoothTypeEnum.linear,
    title: "Linear",
  },
  {
    value: SmoothTypeEnum.stepwise,
    title: "Stepwise",
  },
];

export const LINE_WIDTH_OPTIONS = [
  {
    value: "2",
    title: "2",
  },
  {
    value: "4",
    title: "4",
  },
  {
    value: "6",
    title: "6",
  },
  {
    value: "8",
    title: "8",
  },
];
