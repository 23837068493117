import { gql, useLazyQuery } from "@apollo/client";
import ExtensionIcon from "@mui/icons-material/Extension";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, { Suspense, useEffect, useState } from "react";
import AccessSection from "../basic/AccessSection";
import ServiceSection from "../basic/ServiceSection";
import DescriptionSection from "../basic/DescriptionSection";
import PropListItem from "../PropListItem";
import HumanReadableProperty from "../geo-timer/HumanReadableProperty";
import { updatedBy } from "../../../utils/updatedBy";
import LabelIcon from "../../icons/labelIcon";

const GET_OBJECT_BY_ID = gql`
    query getObject($id: UUID!) {
        object(id: $id) {
            id
            name
        }
    }
`;

const GET_OBJECT_PROPERTY = gql`
    query getObjectProperty($id: UUID!) {
        objectProperty(id: $id) {
            id
            spec {
                id
                description
            }
            property
            object {
                id
                name
            }
        }
    }
`;

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 1,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const DataboxGeneralTab = ({ item, type, dashboardId, groupId, widgetId }) => {
  const [loadObjectProperty, {
    loading: loadingProperty,
    data,
  }] = useLazyQuery(GET_OBJECT_PROPERTY);

  const classes = useStyles({
    listItem: {
      minHeight: "56px",
    },
  });

  const valueValue = () => {
    return item.objectProperties.find((item) => item.key === "valueValue");
  };

  const currentIcon = () => {
    return item.objectProperties.find((item) => item.key === "valueCurrentIcon");
  };

  const currentLabel = () => {
    return item.objectProperties.find((item) => item.key === "valueCurrentLabel");
  };

  const linkedObject = (key) => {
    return key === "valueValue" ? item.objectsToObjectsByObject1Id : "";
  };

  const currentIconName = () => {
    const filtersGroup = currentIcon().value;

    return {
      query: GET_OBJECT_BY_ID,
      config: {
        variables: {
          id: filtersGroup,
        },
      },
    };
  };

  const getPropertyDescription = () => {
    if (loadingProperty) {
      return "Loading";
    }

    if (!loadingProperty && !valueValue().linkedPropertyId) {
      return "n/a";
    }

    if (valueValue().linkedPropertyId && data) {
      return data.objectProperty.spec.description || data.objectProperty.property || "n/a";
    }

    return "n/a";
  };

  useEffect(() => {
    const linkedPropertyId = valueValue().linkedPropertyId;

    if (linkedPropertyId) {
      loadObjectProperty({
        variables: {
          id: linkedPropertyId,
        },
      });
    }
  }, [item]);

  return (
    <>
      <List>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Value</Typography>
        </ListSubheader>

        <PropListItem
          linkedObjects={linkedObject("valueValue")}
          tab="general"
          obj={item}
          item={valueValue()}
          dashboardId={dashboardId}
          widgetId={widgetId}
          groupId={groupId}
          type={type}
        />

        <ListItem>
          <ListItemIcon></ListItemIcon>
          <ListItemText
            secondary={updatedBy(currentIcon().updatedAt, currentIcon().userByBy)}
            primary={
              <Typography variant="body1">
                Current icon:
                {currentIcon().value && (
                  <>
                    <Suspense fallback={<div>Loading...</div>}>
                      <HumanReadableProperty
                        payload={currentIconName()}
                        getValue={(data) => ` ${data?.object?.name}` || " n/a"}
                      ></HumanReadableProperty>
                    </Suspense>
                  </>
                )}
                {!currentIcon().value && "n/a"}
              </Typography>
            }
            onClick={(e) => {
            }}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon></ListItemIcon>
          <ListItemText
            secondary={updatedBy(currentLabel().updatedAt, currentLabel().userByBy)}
            primary={
              <Typography variant="body1">
                Current label: {currentLabel().value || "n/a"}
              </Typography>
            }
          />
        </ListItem>

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Source</Typography>
        </ListSubheader>

        <ListItem>
          <ListItemIcon>
            <ExtensionIcon></ExtensionIcon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {loadingProperty && "Loading..."}
                {!loadingProperty && data && valueValue().linkedPropertyId && data?.objectProperty.object.name || "n/a"}
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <LabelIcon></LabelIcon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {getPropertyDescription()}
              </Typography>
            }
          />
        </ListItem>

        <ServiceSection classes={classes} item={item} />

        <AccessSection classes={classes} item={item} />

        {item.description && <DescriptionSection classes={classes} item={item} />}
      </List>
    </>
  );
};

export default DataboxGeneralTab;
