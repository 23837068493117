import { gql, useApolloClient, useMutation } from "@apollo/client";
import { Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import { msg } from "../../../../messages";
import CommonModal from "../../../CommonModal";
import CustomAutocomplete from "../../../CustomAutocomplete";
import { modes, modesForNoAggregation, modesPropertyIsString, stringTypeId } from "../constants";
import CustomInput from "../../../CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { isMonitoringObject } from "../../../../utils/objectType";

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const GET_PROPERTIES_BY_SCHEMA = gql`
  query getSchema($schemaId: UUID!) {
    schema(id: $schemaId) {
      mTags
      schemataByParentSchemaId(filter: { mTags: { equalTo: ["application", "monitor", "object monitoring item"]} }) {
          id
          name
          mTags
          schemaProperties(orderBy: [GROUP_NAME_ASC, DESCRIPTION_ASC]) {
              id
              key
              groupName
              property
              description
              type {
                  name
              }
              valueSet
              valueRange
          }
      }
      schemaProperties(orderBy: [GROUP_NAME_ASC, DESCRIPTION_ASC]) {
          id
          typeId
          description
          property
          groupName
      }
    }
  }
`;

const StaticTableColumnsModal = ({
  isEdit,
  parameterSchema,
  condition,
  conditionIndex,
  onResolve,
  onReject,
  isOpen,
  columns,
  mode,
  settingsPeriod
}) => {
  const client = useApolloClient();
  const [property, setProperty] = useState(null);
  const [localMode, setMode] = useState(null);
  const [properties, setPropertiesList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [updateProperty, { loading: updatingProperty }] =
    useMutation(UPDATE_PROPERTY);

  const submit = () => onResolve();

  const reject = () => onReject();

  const validationSchema = yup.object({
    alias: yup.string().max(50, 'Custom name must be less than 50 characters'),
  });

  const formik = useFormik({
    initialValues: {
      alias: property?.alias || ''
    },
    validationSchema,
    onSubmit: (formikValues) => {
      const oldValues = cloneDeep(columns.value.columns);

      if (isEdit) {
        oldValues.splice(conditionIndex, 1, {
          mode: localMode,
          alias: formikValues.alias,
          value: {
            value: property.value,
            title: property.title,
          },
        });
        const patch = {
          ...columns.value,
          columns: [...oldValues],
        };
        handleEditProperty(patch);
      } else {
        oldValues.push({
          mode: localMode,
          alias: formikValues.alias,
          value: {
            value: property.value,
            title: property.title,
          },
        });
        const patch = {
          ...columns.value,
          columns: [...oldValues],
        };
        handleEditProperty(patch);
      }

    },
  });


  const getProperties = async () => {
    const schemaId = parameterSchema;
    if (schemaId) {
      setLoading(true);
      try {
        const result = await client.query({
          query: GET_PROPERTIES_BY_SCHEMA,
          variables: {
            schemaId,
          },
          fetchPolicy: "network-only",
        });

        const fragmentProperties = result.data.schema.schemataByParentSchemaId.map(item => {
          const infoNameProperty = item.schemaProperties.find(prop => prop.key === 'infoName');
          const stateValueProperty = item.schemaProperties.find(prop => prop.key === 'stateValue');

          return {
            id: stateValueProperty.id,
            title: infoNameProperty ? infoNameProperty.value || item.name : null,
            value: stateValueProperty ? stateValueProperty.id : null,
            typeId: stringTypeId,
          };
        });

        const ownProperties = result.data?.schema?.schemaProperties.map((item) => {
          return {
            value: item.id,
            typeId: item.typeId === stringTypeId ? "string" : "",
            title: `${item.groupName}/${item?.description || item.property}`,
            ...item,
          };
        });

        if (isMonitoringObject(result.data.schema.mTags)) {
          setPropertiesList(fragmentProperties);
        } else {
          setPropertiesList(ownProperties);
        }

        if (isEdit) {
          setProperty(condition);
          setMode(mode);
          formik.setFieldValue('alias', condition?.alias)
        }
      } catch (err) {
        toast.error(`${err.toString()}`);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getProperties();
  }, [columns]);

  const handleEditProperty = (value) => {
    updateProperty({
      variables: {
        input: {
          id: columns.id,
          patch: {
            value,
          },
        },
      },
    }).then(() => {
      submit();
    });
  };

  const getOptions = () => {
    if (settingsPeriod?.value && settingsPeriod?.value === 'no aggregation') {
      return modesForNoAggregation
    }

    if (property?.typeId === "string" ) {
      return modesPropertyIsString
    }

    return modes
  }
  return (
    <CommonModal
      key="ConditionModal"
      modalOpen={isOpen}
      title={isEdit ? "Edit column" : "Add column"}
      handleClose={reject}
      buttons={
        <>
          <Button color="inherit" onClick={reject}>{msg.default.cancel}</Button>
          <Button
            color="primary"
            disabled={!property?.value || !localMode}
            onClick={formik.handleSubmit}
          >
            {updatingProperty ? (
              <CircularProgress size={23} />
            ) : isEdit ? (
              msg.default.save
            ) : (
              msg.default.add
            )}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <CustomAutocomplete
            name="schemaProperties"
            label="Property"
            list={properties}
            disabled={loading}
            value={property?.value || ""}
            onChange={(e) => {
              setProperty(e.target.rawValue);
            }}
          />
        </Grid>
        <Grid item>
          <CustomAutocomplete
            name="mode"
            label="Mode of calculation"
            list={getOptions()}
            value={localMode}
            onChange={(e) => {
              setMode(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <CustomInput
            clearFieldIcon={true}
            name="alias"
            onBlur={formik.handleBlur}
            value={formik.values.alias}
            onChange={formik.handleChange}
            error={formik.touched.alias && Boolean(formik.errors.alias)}
            helperText={formik.touched.alias && formik.errors.alias}
            label="Custom name"
          />
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(StaticTableColumnsModal);
