import cloneDeep from "lodash.clonedeep";

interface ObjectData {
  id: number;
  name: string;
}

interface DataItem {
  time: string;
  values: ObjectData[];
}

interface Table {
  headers: ObjectData[];
  rows: DataItem[];
}

function ensureConsistentOrder(data: DataItem[]): DataItem[] {
  const dataCloned: DataItem[] = cloneDeep(data);
  return dataCloned.map((item) => ({
    ...item,
    values: item.values.sort((a, b) => a.id - b.id),
  }));
}

function generatePropertyHistoryTable(data: DataItem[]): Table {
  if (!data?.length) {
    return { headers: [], rows: [] };
  }

  const ensuredOrder = ensureConsistentOrder(data);

  return {
    headers: ensuredOrder[0].values,
    rows: ensuredOrder,
  };
}

export { generatePropertyHistoryTable };
